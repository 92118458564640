import React, { useState, useEffect } from "react";
import SpanImage from "../comp/SpanImage";
import { useParams, Link } from "react-router-dom";
import CompElement from "../comp/CompElement";
import Navigation from "../comp/Navigation";
import HomeElement from "../comp/HomeElement";
import { VELKI_API_URL, VELKI_API_KEY } from "../config/Constant";

export default function Complain() {
  const [admindata, setAdmindata] = useState({ admindata: {} });
  let { agentId } = useParams();
  
  const fetchDetaild = () => {
    fetch(`${VELKI_API_URL}/api/?agent_id=${agentId}`)
      .then((res) => res.json())
      .then((items) => setAdmindata(items));
  };

  useEffect(() => {
    fetchDetaild();
  },[]);
  
  console.log("Admindata.status===200");
  console.log(admindata.status);
  if (admindata.status === 200) {
    return (
      <div id="wrapper">
        <span>
          <Link to="/">{
            /* <SpanImage url="images/agent_list1.jpg" width="600" height="80" /> */
            <SpanImage url="/images/velki-agentlist-top.jpg" width="100%" height="auto" /> 
            }
            {/* <h1>vellki.live-এজেন্ট লিস্ট</h1> */}
          </Link>
        </span>
        <Navigation />

        <CompElement
          id={admindata.data[0].id}
          fb={admindata.data[0].agent_fb}
          type={admindata.data[0].agent_type}
          phone={admindata.data[0].agent_phone}
        />
        {/* {admindata.status}<br/>
      {admindata.itemCount}<br/>
      {admindata.data[0].id}<br/>
      {admindata.data[0].agent_name}<br/>
      {admindata.data[0].agent_phone}<br/>
      {admindata.data[0].id}<br/>
      {admindata.data[0].id}<br/> */}
      </div>
    );
  }

  return (
    <>
      <div id="wrapper">
        <span>
          <Link to="/">
            <h1>vellki.live-এজেন্ট লিস্ট</h1>
            {/* <SpanImage url="images/agent_list1.jpg" width="600" height="80" /> */}
          </Link>
        </span>
        <Navigation />
        <HomeElement
          title="Sorry"
          head="No Record Found/Loading"
          desc="No Record Found for this id"
        />
      </div>
    </>
  );
}
