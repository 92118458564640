import React, { Component } from "react";
import SpanImage from "./comp/SpanImage";
import { Link } from "react-router-dom";
import Navigation from "./comp/Navigation";
import Agent, { AgentBody } from "./comp/Agent";
import { VELKI_API_URL, VELKI_API_KEY } from "./config/Constant";

let admindata = [
  {
    id: "0",
    agent_type: "Site Admin",
    agent_name: "Agent Name",
    agent_phone: "000000000000",
    agent_upline: "1",
    agent_altphone: null,
    agent_email: "",
    agent_uodateon: "2022-09-17 01:10:29",
    agent_creted: "2022-09-17 01:10:29",
  },
];

export default class Error extends Component {
  constructor() {
    super();
    this.state = {
      agents: admindata,
      isloading: false,
    };
  }
  async componentDidMount() {
    console.log("cdm");
    let url = `${VELKI_API_URL}/api/?agent_type=site_admin`; //"http://localhost/agent/api/?agent_type=site_admin";
    let res = await fetch(url);
    let parsedData = await res.json();
    this.setState({
      agents: parsedData.data,
      isloading: false,
    });
    console.log("Parsed");
    console.log(parsedData.data);
  }

  render() {
    const { agents, isloading } = this.state;
    console.log("Render Agents:");
    console.log(agents);
    console.log(isloading);

    return (
      <div id="wrapper">
        <span>
          <Link to="/">
          {
            /* <SpanImage url="images/agent_list1.jpg" width="600" height="80" /> */
            <SpanImage url="/images/velki-agentlist-top.jpg" width="100%" height="auto" /> 
          }
            {/* <h1>vellki.live-এজেন্ট লিস্ট</h1> */}
          </Link>
        </span>
        <Navigation />
        <div className="col-12">
          <div id="dashboard-contents">
            <div id="licenseinfo">
              <div className="header">
                <div className="title upper">
                  Error Occured: Try Again
                </div>
                <div className="legend"></div>
              </div>
              <div className="body">
                <div className="code" style={{ width: "95%" }}>
                  <table className="data" style={{ width: "95%" }}>
                    <tbody>                      
                      <tr>
                        <th>Description</th>
                      </tr>
                      <tr>
                        <td className="header">
                          <div className="code" style={{ width: "95%" }}>
                            <h1>
                            Sorry! Some Error Occured! Please try Agan...
                            </h1>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>         
        </div>
      </div>
    );
  }
}
